import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Programme.css";

export default function Programme({
  itemIndex,
  category,
  title,
  description,
  routeText,
  route,
  thumbnail,
  href,
}) {
  const [isMobileView, setIsMobileView] = useState(false);
  useEffect(() => {
    window.innerWidth < 992 ? setIsMobileView(true) : setIsMobileView(false);
    window.addEventListener("resize", () =>
      window.innerWidth < 992 ? setIsMobileView(true) : setIsMobileView(false)
    );
  }, []);
  return (
    <section
      className="programme"
      style={
        itemIndex % 2 != 0 && !isMobileView
          ? { flexDirection: "row-reverse", paddingLeft: 20 }
          : { paddingRight: 20 }
      }
    >
      <div
        className="programme-image"
        style={{ backgroundImage: `url(${thumbnail})` }}
      ></div>
      <div className="programme-content">
        <h1 className="programme-category">{category}</h1>
        <h2 className="programme-title">{title}</h2>
        <p className="programme-description">{description}</p>
        <div className="programme-actions">
          {/* <Link className="booknow-btn" to={route}>
            Book Now
          </Link> */}
          <a
            className="booknow-btn"
            href={`https://wa.me/254703780424?text=${encodeURIComponent(
              `${routeText}`
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Get In Touch For Booking
          </a>
          <a
            className="learnmore-btn"
            href={href}
            target={"_blank"}
            rel="noopener noreferrer"
          >
            Learn More
          </a>
        </div>
      </div>
    </section>
  );
}
