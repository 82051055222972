import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";

export default function Header() {
  const [isHovered, setHovered] = useState({ course: false, dropdown: null });
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const courses = {
    "Full Spectrum": [
      { label: "Web Development", link: "/courses/webdevelopment" },
      { label: "App Development", link: "/courses/appdevelopment" },
      { label: "Data Analysis", link: "/courses/dataanalysis" },
      { label: "Machine Learning", link: "/courses/machinelearning" },
      { label: "Cloud Architecture", link: "/courses/cloudarchitecture" },
      { label: "Cybersecurity", link: "/courses/cybersecurityanalysis" },
      { label: "UI/UX Designing", link: "/courses/uiuxdesigning" },
      { label: "Full Stack Development", link: "/courses/fullstackdeveloper" },
      { label: "Software Engineer", link: "/courses/softwareengineering" },
    ],
    "Specialised Courses": [
      {
        label: "Foundation in Programming",
        link: "/courses/foundationprogrammingwebdev",
      },
      { label: "Frontend Development", link: "/courses/deepfrontenddev" },
      { label: "Backend Development", link: "/courses/backenddevdb" },
      { label: "App Development", link: "/courses/appdevspecialization" },
      { label: "Cloud Architecture", link: "/courses/cloudarchitectureintro" },
      { label: "Data Analysis & ML", link: "/courses/dataanalysisml" },
      { label: "Cybersecurity Fundamentals", link: "/courses/cybersecurity" },
      { label: "Prototyping & Design", link: "/courses/prototypin" },
    ],
    "Language Mastering": [
      { label: "Python", link: "/courses/pytho" },
      { label: "C Programming", link: "/courses/cprogramming" },
      { label: "C++", link: "/courses/cplusplus" },
      { label: "C#", link: "/courses/csharp" },
      { label: "Java", link: "/courses/java" },
      { label: "JavaScript", link: "/courses/javascript" },
      { label: "R Programming", link: "/courses/rprogramming" },
    ],
    "Special Courses": [
      { label: "3D Web Development", link: "/courses/threed" },
      { label: "Dj Code", link: "/courses/codedj" },
    ],
    STEM: [{ label: "STEM Concepts", link: "/courses/stemconcepts" }],
    Animation: [{ label: "Animation Basics", link: "/courses/animation" }],
    "Game Development": [{ label: "Game Dev", link: "/courses/gaming" }],
  };

  return (
    <header className="header">
      <h1 className="logo">
        CODE <span style={{ color: "#3691CD" }}>RHYTHM</span>
      </h1>
      <div
        className={`hamburger ${menuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav className={`nav ${menuOpen ? "open" : ""}`}>
        <ul className="navigations">
          <li className="nav-option">
            <a href="/#">Home</a>
          </li>
          <li
            className="nav-option"
            onMouseEnter={() => setHovered({ ...isHovered, course: true })}
            onMouseLeave={() => setHovered({ course: false, dropdown: null })}
          >
            <a href="/#courses">Courses Offered</a>
            {isHovered.course && (
              <div className="drops">
                <ul className="dropdown">
                  {Object.keys(courses).map((course, i) => (
                    <li
                      key={i}
                      onMouseEnter={() =>
                        setHovered({ ...isHovered, dropdown: i })
                      }
                      onMouseLeave={() =>
                        setHovered({ ...isHovered, dropdown: null })
                      }
                    >
                      <span className="link">{course}</span>
                      {isHovered.dropdown === i && (
                        <ol
                          className="drop-dropdown"
                          onMouseEnter={() =>
                            setHovered({ ...isHovered, course: true })
                          }
                          onMouseLeave={() =>
                            setHovered({ course: false, dropdown: null })
                          }
                        >
                          {courses[course].map((sub, j) => (
                            <li key={j}>
                              <Link to={sub.link} className="link">
                                {sub.label}
                              </Link>
                            </li>
                          ))}
                        </ol>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </li>
          <li className="nav-option">
            <Link to="/programmes">Programmes</Link>
          </li>
        </ul>
      </nav>
      <a
        href="https://forms.gle/yk3QhrrxtYQ8T5f49"
        target="_blank"
        className="button"
        rel="noreferrer"
      >
        Enroll Now
      </a>
    </header>
  );
}
