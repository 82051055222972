import "./Programmes.css";
import Programme from "./components/Programme";
import SEO from "../../components/SEO";
import conicalImage from "./assets/img/conical_image.jpeg";
// program images
import techSavvyImage from "./assets/img/techsavvy.jpeg";
import summerCodingCampImage from "./assets/img/animation.jpeg";

export default function Programmes() {
  const programmes = [
    {
      category: "Coding Camp",
      thumbnail: techSavvyImage,
      title: "Techsavvy",
      routeText:
        "Hello, I'm interested in learning more about Techsavvy programme!",
      description:
        "Integrates Python programming with 6 different skill set necessary for a well-rounded coding education experience.",
      route: "/payment/summercodingcamp",
      href: "../programs/NOVEMBER_CODING_CAMP.pdf",
    },

    {
      category: "Bootcamp",
      thumbnail: summerCodingCampImage,
      title: "Summer Coding Camp",
      description:
        "For kids aged 6 to 17, featuring hands-on projects in animation, Python programming, and dance.",
      route: "/payment/summercodingcamp",
      routeText:
        "Hello, I'm interested in learning more about Summer Coding Camp programme!",
      routeText: "",
      href: "../programs/SUMMER_CODING_CAMP_BROCHURE.pdf",
    },
  ];
  return (
    <>
      <SEO
        title="Code Rhythm Programs"
        description="Premier programs designed for both kids and adults from the age of 6. Our programs offers immersive, hands-on experiences in animation, Python programming, and dance among many others, ensuring a fun and educational programme. Participants will build exciting projects, learn valuable coding skills, and explore their creativity. Secure your child's spot today for a transformative and enriching summer! Limited slots available."
        image={conicalImage}
        url="https://www.coderhythm.electrixitaty.co/programmes"
        keywords={
          "Summer Coding Camp,Kids Coding Classes, Animation for Kids, Python Programming for Kids, Dance Classes for Kids, Hands-on Learning, Educational Summer Camp, Fun Summer Activities, Creative Summer Camp, Code Rhythm Camp, Code Rhythm, coding, creativity, tech education"
        }
      />
      <div className="programmes">
        {programmes.map(
          (
            { category, title, description, route, href, routeText, thumbnail },
            index
          ) => (
            <Programme
              key={index}
              itemIndex={index}
              category={category}
              title={title}
              thumbnail={thumbnail}
              description={description}
              route={route}
              routeText={routeText}
              href={href}
            />
          )
        )}
      </div>
    </>
  );
}
